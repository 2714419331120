/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router' // eslint-disable-line
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

const sanitizeLangPrefixUrl = url => url?.replace(/\/[a-z]{2}\//, '/')
const generateHrefLangUrl = ({ origin, pathname, prefix }) =>
  `${origin}${prefix ? `/${prefix}` : ''}${sanitizeLangPrefixUrl(pathname)}`

function SEO({ description, meta, title, thumbnail }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const { author, siteUrl, title: name } = site.siteMetadata
  const { locale: lang } = useIntl()
  const { pathname } = useLocation()

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name,
    description: metaDescription,
    url: siteUrl,
    logo:
      'https://s3.amazonaws.com/ckl-website-static/wp-content/uploads/2019/07/cheesecake-icon.png',
    sameAs: [
      'https://www.facebook.com/cheesecakelabs',
      'https://twitter.com/cheesecakelabs',
      'https://www.instagram.com/cheesecakelabs/',
      'https://cheesecakelabs.com/',
      'https://www.linkedin.com/company/cheesecake-labs/',
      'https://clutch.co/profile/cheesecake-labs',
      'https://maps.app.goo.gl/7UTZQA2ayEcN7ZWz8',
      'https://github.com/CheesecakeLabs',
    ],
    mainEntityOfPage: 'https://cheesecakelabs.com/',
    foundingDate: '2013',
    areaServed: 'North America',
    foundingLocation: {
      '@type': 'Place',
      name: 'Florianopolis, State of Santa Catarina, Brazil',
    },
    member: {
      '@type': 'Person',
      name: 'Marcelo Gracietti',
      jobTitle: 'CEO',
      url: 'https://www.linkedin.com/in/gracietti/',
      nationality: {
        '@type': 'Country',
        name: 'Brazil',
      },
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.9',
      reviewCount: '51',
      author: {
        '@type': 'Organization',
        name: 'Clutch',
        url: 'https://clutch.co/profile/cheesecake-labs',
      },
    },
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress: '391 Sutter St, Suite',
        addressLocality: 'San Francisco',
        addressRegion: 'CA',
        postalCode: '94108',
        addressCountry: 'US',
      },
      {
        '@type': 'PostalAddress',
        streetAddress: 'Av. Des. Vitor Lima 260',
        addressLocality: 'Florianopolis',
        addressRegion: 'SC',
        postalCode: '88040-400',
        addressCountry: 'BR',
      },
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+1 415 766 8860',
      email: 'info@cheesecakelabs.com',
      contactType: 'customer service',
    },
  }

  const metaData = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:site_name`,
      content: `Cheesecake Labs`,
    },
    {
      property: `og:url`,
      content: siteUrl,
    },
    {
      property: `og:image`,
      content: `https:${thumbnail}`,
    },
    {
      property: `og:image:width`,
      content: '1200',
    },
    {
      property: `og:image:height`,
      content: '630',
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: `https:${thumbnail}`,
    },
    {
      name: `twitter:site`,
      content: `https://twitter.com/cheesecakelabs`,
    },
  ]

  const hrefLangData = [
    {
      hrefLang: 'en-us',
      href: generateHrefLangUrl({
        pathname,
        origin: siteUrl,
      }),
    },
    {
      hrefLang: 'en',
      href: generateHrefLangUrl({
        prefix: 'en',
        pathname,
        origin: siteUrl,
      }),
    },
    {
      hrefLang: 'pt',
      href: generateHrefLangUrl({
        prefix: 'br',
        pathname,
        origin: siteUrl,
      }),
    },
    {
      hrefLang: 'x-default',
      href: generateHrefLangUrl({
        pathname,
        origin: siteUrl,
      }),
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${name}`}
      meta={metaData.concat(meta)}
    >
      {hrefLangData.map(({ hrefLang, href }) => (
        <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
      ))}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  thumbnail: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
}

export default SEO
